// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-link:hover {
    color: #0283AB;
}

.dropdown-item {
    border-bottom: 2px solid #f2f2f2;
    padding: 10px 0;
    color: #102942;
}

.no-bottom {
    border-bottom: none;
}

.dropdown-item:hover {
    background-color: #f2f2f2;
    text-decoration: underline;
}

.blue-border {
    border: 2px solid #0283AB;
}

.forefront {
    z-index: 999;
}

.desktop-menu {
    display: none;
}

@media (min-width: 768px) {
    .desktop-menu {
        display: flex;
    }

    .bm-burger-button {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Navbar/Navbar.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,gCAAgC;IAChC,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,0BAA0B;AAC9B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".nav-link:hover {\n    color: #0283AB;\n}\n\n.dropdown-item {\n    border-bottom: 2px solid #f2f2f2;\n    padding: 10px 0;\n    color: #102942;\n}\n\n.no-bottom {\n    border-bottom: none;\n}\n\n.dropdown-item:hover {\n    background-color: #f2f2f2;\n    text-decoration: underline;\n}\n\n.blue-border {\n    border: 2px solid #0283AB;\n}\n\n.forefront {\n    z-index: 999;\n}\n\n.desktop-menu {\n    display: none;\n}\n\n@media (min-width: 768px) {\n    .desktop-menu {\n        display: flex;\n    }\n\n    .bm-burger-button {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
